import { DefaultTheme } from 'styled-components'

/**
 * Target size for each breakpoint (reference: https://www.npmjs.com/package/styled-components-breakpoint#default-breakpoints)
 * mobile	0px (0em)	        Targeting all devices
 * tablet	737px (46.0625em)	Targeting devices that are LARGER than the iPhone 6 Plus (which is 736px in landscape mode)
 * desktop	1195px (74.6875em)	Targeting devices that are LARGER than the 11" iPad Pro (which is 1194px in landscape mode)
 */

export const breakpoints = {
  mobile: 0,
  tablet: 737,
  desktop: 1195,
}

const theme: DefaultTheme = {
  colors: {
    midGreen: '#2CA46B',
    lightGreen: '#98CAD1',
    lighterGreen: '#E6F5F6',
    lightGreen2: '#D0F2E0',
    primaryGreen: '#148567',
    darkGreen: '#0D5450',
    darkGreen1: '#061A15',
    lightBlue: '#E2F6F6',
    midBlue: '#89CCD2',
    primaryBlue: '#89CCD2',
    blue: '#4F7DC1',
    blue2: '#2960E3',
    blue3: '#D7E1FA',
    yellow: '#E5A84D',
    yellow1: '#F9E8D0',
    lightGrey1: '#F5F5F5',
    lightGrey2: '#D7D7D7',
    lightGrey3: '#EFF1EC',
    midGrey1: '#999999',
    midGrey2: '#666666',
    midGrey3: '#A2A9A9',
    midGrey4: '#5B6362',
    orange: '#E79D0D',
    darkRed: '#CF0000',
    red: '#EA645C',
    lightRed: '#F5E2E0',
    black: '#000',
    white: '#fff',
    coral: '#f08080',
    lightPink: '#fdd',
    purple: '#66208A',
    purple1: '#EDEAFF',
    pink: '#FAD8F9',
    forestGreen: '#2E8420',
    background: '#EFF0F6',
    lightPurple: '#c6aff9',
    brightGreen: 'rgba(45, 227, 73, 0.33)',
    slightGreen: 'rgba(10, 83, 21, 0.81)',
    brightRed: 'rgba(255, 0, 0, 0.17)',
    slightRed: 'rgba(191, 0, 0, 0.91)',
    blushRed: '#EABFBE',
  },
  breakpoints,
}

export default theme
