import { request } from './api'
import { Staff, StaffSnippet } from '../types/staffType'

export const retrieveSnippets = async () => {
  return request<{ snippets: StaffSnippet[] }>({
    uri: `/users/me/snippets`,
    options: { method: 'GET' },
  })
}

export const retrieveStaff = async () => {
  return request<Staff>({
    uri: `/users/me`,
    options: { method: 'GET' },
  })
}

export const retrieveSupportChatToken = async () => {
  return request<{ token: string }>({
    uri: `/users/me/support-chat/token`,
    options: { method: 'GET' },
  })
}

export const retrieveProviderMetrics = (timeframe = 'monthly') => {
  return request<{
    consultRatings: { month?: string; week?: string }[]
    consultResponseSla: { month?: string; week?: string }[]
    consultStartSla: { month?: string; week?: string }[]
  }>({
    uri: `/users/me/metrics/${timeframe}`,
    options: { method: 'GET' },
  })
}
