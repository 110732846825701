// ExperimentContext.ts
import { createContext } from 'react'
import { ExperimentClient } from '@amplitude/experiment-js-client'

export interface AmplitudeExperimentContextValue {
  experiment?: ExperimentClient
  isVariantActive: (variantId: string, variantValue?: string) => boolean
  /**
   * @description Returns true if the user is in the experiment with variant `control`.
   */
  isControlActive: (variantId: string, defaultControl?: string) => boolean
  getPayloadVariant: <T>(variantId: string) => T | null
  trackExposureEvent: (key: string) => void
}

export const AmplitudeExperimentContext = createContext<AmplitudeExperimentContextValue | null>(
  null,
)
