import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Meta Serif Pro';
    src: url("/static/meta-serif-pro--medium.woff2") format("woff2"),
         url("/static/meta-serif-pro--medium.woff") format("woff"),
         url("/static/meta-serif-pro--medium.otf") format("opentype");
  }

  @font-face {
    font-family: 'MaisonNeue-Medium';
    src: url("/static/maison-neue--medium.woff2") format("woff2"),
         url("/static/maison-neue--medium.woff") format("woff"),
         url("/static/maison-neue--medium.otf") format("opentype");
  }

  @font-face {
    font-family: 'MaisonNeue-Demi';
    src: url("/static/maison-neue-demi.ttf") format("truetype");
  }

  @font-face {
    font-family: 'MaisonNeue-Light';
    src: url("/static/maison-neue-light.ttf") format("truetype");
  }
  @font-face {
    font-family: 'Greycliff CF';
    font-weight: 200;
    src: url("/static/GreycliffCF-Light.otf") format("opentype");
  }

  @font-face {
    font-family: 'Greycliff CF';
    font-weight: normal;
    font-weight: 400;
    src: url("/static/GreycliffCF-Regular.otf") format("opentype");
  }

  @font-face {
    font-family: 'Greycliff CF';
    font-weight: bold;
    font-weight: 700;
    src: url("/static/GreycliffCF-DemiBold.otf") format("opentype");
  }

  @font-face {
    font-family: 'Quincy CF';
    font-weight: 100;
    src: url("/static/QuincyCF-Thin.otf") format("opentype");
  }

  @font-face {
    font-family: 'Quincy CF';
    font-weight: 200;
    src: url("/static/QuincyCF-Light.otf") format("opentype");
  }

  @font-face {
    font-family: 'Quincy CF';
    font-weight: 400;
    src: url("/static/QuincyCF-Regular.otf") format("opentype");
  }

  @font-face {
    font-family: 'Quincy CF';
    font-weight: bold;
    font-weight: 700;
    font-style: italic;
    src: url("/static/QuincyCF-Bold-Italic.otf") format("opentype");
  }

  a {
    cursor: pointer;
  }

  h1, h2, h3, h4, h5 {
    color: ${props => props.theme.colors.primaryGreen};
    font-weight: normal;
    font-family: 'Greycliff CF';
  }

  body {
    margin: 0;
    font-family: 'Greycliff CF', 'Helvetica', 'sans-serif';
    line-height: 1.4rem;
  }
`
