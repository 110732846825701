import axios from 'axios'
import { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh'

import { getSavedOktaTokens } from '../utils/auth'

const apiVersion = 'v7'

interface RequestConfig {
  uri: string
  headers?: { [key: string]: string }
  options?: AxiosAuthRefreshRequestConfig
  accessToken?: string
}

export async function rawRequest(request: RequestConfig) {
  const { oktaAccess, tokenType, role } = getSavedOktaTokens()
  const authToken =
    oktaAccess && tokenType ? `${tokenType} ${oktaAccess}` : `JWT ${request.accessToken}`
  const options = {
    credentials: 'include',
    headers: request.headers,
  }
  let config = { ...options, ...request.options }
  config = {
    ...config,
    headers: {
      ...((request.accessToken || (oktaAccess && tokenType)) && {
        Authorization: authToken,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-Requested-Role': role as string,
      }),
      ...config.headers,
    },
  }
  return axios(request.uri, config)
}

export async function request<T>(request: RequestConfig) {
  const rawRequestOptions = {
    ...request,
    uri: `${process.env.FOUND_API_ENDPOINT}/${apiVersion}${request.uri}`,
    headers: {
      Accept: 'application/json; charset=utf-8',
      'Content-Type': 'application/json; charset=utf-8',
      ...request.headers,
    },
  }
  const response = await rawRequest(rawRequestOptions)
  return response?.data as T
}

export async function unauthedRequest<T>(requestData: RequestConfig) {
  return request<T>({
    ...requestData,
    options: {
      ...requestData.options,
      skipAuthRefresh: true,
    },
  })
}
