import React, { useState, useEffect } from 'react'
import {
  AmplitudeUserProvider,
  Experiment,
  ExperimentClient,
} from '@amplitude/experiment-js-client'
import amplitude from 'amplitude-js'

import {
  AmplitudeExperimentContext,
  AmplitudeExperimentContextValue,
} from 'components/AmplitudeExperiments/AmplitudeExperimentContext'
import { AmplitudeExposureTrackingProvider } from 'services/amplitude'

const isAmplitudeEnabled = () => {
  return (
    typeof window !== 'undefined' &&
    process.env.AMPLITUDE_API_KEY &&
    process.env.AMPLITUDE_API_KEY !== 'DEBUG' &&
    process.env.AMPLITUDE_EXP_API_KEY &&
    process.env.AMPLITUDE_EXP_API_KEY !== 'DEBUG'
  )
}

const AmplitudeExperimentProvider: React.FC<Record<string, unknown>> = ({ children }) => {
  const [experiment, setExperiment] = useState<ExperimentClient | undefined>(undefined)
  const startExperiment = async (experiment: ExperimentClient) => {
    const exp = await experiment.fetch()
    setExperiment(exp)
  }
  useEffect(() => {
    if (isAmplitudeEnabled()) {
      try {
        const instance = amplitude.getInstance()
        instance.init(process.env.AMPLITUDE_API_KEY as string)
        // `initializeWithAmplitudeAnalytics` will cause a race condition that when
        // experiment object are not initialized and call the fetch function.
        const client = Experiment.initialize(process.env.AMPLITUDE_EXP_API_KEY as string, {
          userProvider: new AmplitudeUserProvider(instance),
          exposureTrackingProvider: new AmplitudeExposureTrackingProvider(instance),
        })

        startExperiment(client)
      } catch {
        // do nothing
      }
    }
  }, [])

  const isVariantActive = (variantId: string, variantValue?: string) => {
    if (!experiment) {
      return false
    }

    const variantObject = experiment.variant(variantId)
    if (variantValue) {
      return variantObject.value === variantValue
    }

    return !!variantObject.value && variantObject.value !== 'control'
  }

  const isControlActive = (variantId: string, defaultControl = 'control') =>
    isVariantActive(variantId, defaultControl)

  function getPayloadVariant<T>(variantId: string): T | null {
    if (!experiment) {
      return null
    }

    const variantObject = experiment.variant(variantId)
    return variantObject.payload as T
  }

  const trackExposureEvent = (key: string) => {
    if (!experiment) {
      return false
    }
    experiment.exposure(key)
  }

  const value: AmplitudeExperimentContextValue = {
    experiment,
    isVariantActive,
    isControlActive,
    getPayloadVariant,
    trackExposureEvent,
  }

  return (
    <AmplitudeExperimentContext.Provider value={value}>
      {children}
    </AmplitudeExperimentContext.Provider>
  )
}

export { AmplitudeExperimentContext, AmplitudeExperimentProvider }
