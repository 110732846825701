import App from 'next/app'
import React from 'react'
import { ThemeProvider } from 'styled-components'
import * as Sentry from '@sentry/nextjs'
import { initAmplitude } from 'services/amplitude'
import { GlobalStyle } from '../styles/GlobalStyles'
import Page from '../components/Page'
import theme from '../styles/theme'
import { AmplitudeExperimentProvider } from 'components/AmplitudeExperiments/AmplitudeExperimentProvider'
import { OktaAuth } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import { withRouter } from 'next/router'

const oktaAuth = new OktaAuth({
  issuer: process.env.PUBLIC_OKTA_AUTH_ISSUER || 'https://joinfound.okta.com/oauth2/default',
  clientId: process.env.PUBLIC_OKTA_AUTH_CLIENT_ID,
  redirectUri: '/callback',
  pkce: true,
  scopes: ['openid', 'email', 'profile', 'offline_access'],
  services: {
    autoRenew: true,
    syncStorage: true,
  },
})

interface Props {
  Component: React.ReactElement
  pageProps: Record<string, unknown>
}

export const MyApp = withRouter(
  class MyApp extends App<Props> {
    componentDidMount() {
      const jssStyles = document.querySelector('#jss-server-side')
      if (jssStyles) {
        jssStyles?.parentElement?.removeChild(jssStyles)
      }
      initAmplitude()
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
      Sentry.withScope(scope => {
        scope.setExtra('componentStack', errorInfo.componentStack)
        Sentry.captureException(error)
      })
    }

    render() {
      oktaAuth.tokenManager.on('error', () => {
        oktaAuth.setOriginalUri(this.props.router.asPath)
        this.props.router.replace(`/login?redirect=${this.props.router.asPath}`)
      })

      const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
        this.props.router.replace(originalUri || '/')
      }

      const handleAuthRequired = () => {
        this.props.router.replace(`/login?redirect=${this.props.router.asPath}`)
      }

      const { Component, pageProps } = this.props
      return (
        <Security
          oktaAuth={oktaAuth}
          restoreOriginalUri={restoreOriginalUri}
          onAuthRequired={handleAuthRequired}
        >
          <AmplitudeExperimentProvider>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <Page {...pageProps}>
                <Component {...pageProps} />
              </Page>
            </ThemeProvider>
          </AmplitudeExperimentProvider>
        </Security>
      )
    }
  },
)

export default MyApp
