import React from 'react'
import { Staff } from './types/staffType'

export type UserContextProps = {
  isAuthenticated: boolean
  accessToken: string
  user?: Staff
}

export const UserContext = React.createContext<UserContextProps>({
  isAuthenticated: false,
  accessToken: '',
})

export const useUserContext = () => React.useContext(UserContext)
