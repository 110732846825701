import React from 'react'
import Cookies from 'js-cookie'

import { UserContext } from 'userContext'
import userflow from 'userflow.js'
import { useOktaAuth } from '@okta/okta-react'
import { retrieveStaff } from 'services/usersApi'
import { Staff } from 'types/staffType'
import { setAmplitudeUserId } from 'services/amplitude'

interface PageProps {
  children: React.ReactElement
}

const Page = ({ children }: PageProps) => {
  const user = Cookies.get('user') || ''
  const { authState } = useOktaAuth()
  const [contextData, setContextData] = React.useState({
    isAuthenticated: false,
    accessToken: '',
    user: user ? JSON.parse(user) : {},
  })
  const prepareSetup = async () => {
    try {
      if (authState?.isAuthenticated) {
        const res = await retrieveStaff()
        setContextData({
          isAuthenticated: !!authState?.isAuthenticated,
          accessToken: authState?.accessToken?.accessToken || '',
          user: res,
        })
        setAmplitudeUserId(res.id)
      }
    } catch (_e) {
      // do nothing
    }
  }

  React.useEffect(() => {
    prepareSetup()
  }, [authState?.isAuthenticated])

  React.useEffect(() => {
    const userObj = contextData.user as Staff
    userflow.init(process.env.USERFLOW_TOKEN || '')
    if (userObj?.id) {
      userflow.identify(userObj?.id, {
        name: userObj?.fullName,
        email: userObj?.email,
        signed_up_at: new Date().toISOString(),
      })
    }
  }, [contextData.user])

  return <UserContext.Provider value={contextData}>{children}</UserContext.Provider>
}

export default Page
