import React, { useEffect } from 'react'
import { NextPageContext } from 'next'
import Router from 'next/router'
import cookie from 'js-cookie'
import jwtDecode from 'jwt-decode'

import { AuthedStaff } from '../types/staffType'

export enum AuthCookie {
  Token = 'accessToken',
  Refresh = 'refreshToken',
  User = 'user',
}

const saveTokenInCookies = (key: AuthCookie, token: string, expires?: Date | number) => {
  if (!expires) {
    const exp = jwtDecode<{ exp: number }>(token).exp
    expires = new Date(exp * 1000)
  }
  cookie.set(key, token, { expires })
}

export const saveTokens = (tokens: AuthedStaff) => {
  saveTokenInCookies(AuthCookie.Token, tokens.token, 7)
  saveTokenInCookies(AuthCookie.Refresh, tokens.refresh)
  cookie.set(AuthCookie.User, JSON.stringify(tokens.user), { expires: 90 })
}

export const getSavedTokens = () => {
  return {
    access: cookie.get()[AuthCookie.Token],
    refresh: cookie.get()[AuthCookie.Refresh],
  }
}
export const getSavedOktaTokens = () => {
  if (typeof window !== 'undefined') {
    const authState = JSON.parse(window.localStorage.getItem('okta-token-storage') || '{}')
    return {
      oktaAccess: authState?.accessToken?.accessToken || '',
      tokenType: authState?.accessToken?.tokenType || '',
      role: authState?.accessToken?.claims.foundUserRole || '',
    }
  } else {
    return {
      oktaAccess: '',
      tokenType: '',
    }
  }
}

export const login = async (user: AuthedStaff, destination = '/') => {
  saveTokens(user)
  return Router.push(destination)
}

const auth = (_ctx: NextPageContext) => {
  const { oktaAccess } = getSavedOktaTokens()
  return oktaAccess
}

export const logout = (allWindows: boolean) => {
  Object.values(AuthCookie).map(value => cookie.remove(value))
  // to support logging out from all windows
  if (allWindows) {
    window.localStorage.setItem('logout', String(Date.now()))
  }
  Router.push('/login')
  return null
}

// HOC to protect pages that need authentication.
export const withAuthSync = (WrappedComponent: any) => {
  const Wrapper = (props: JSX.IntrinsicAttributes) => {
    // sync logout between tabs (i.e. logout in one tab logs out all tabs)
    const syncLogout = (event: StorageEvent) => {
      if (event.key === 'logout') {
        Router.push('/login')
      }
    }

    useEffect(() => {
      window.addEventListener('storage', syncLogout)
      const { oktaAccess } = getSavedOktaTokens()
      // If there's no accessToken, it means the user is not logged in.
      const redirectPath = `${encodeURIComponent(
        `${window.location.pathname}${window?.location?.search}` || '',
      )}`
      if (!oktaAccess) {
        Router.push(`/login?redirect=${redirectPath}`)
      }

      return () => {
        window.removeEventListener('storage', syncLogout)
        window.localStorage.removeItem('logout')
      }
    }, [])
    return <WrappedComponent {...props} />
  }

  Wrapper.getInitialProps = async (ctx: NextPageContext) => {
    const accessToken = auth(ctx)
    let componentProps = {}
    if (WrappedComponent.getInitialProps) {
      componentProps = await WrappedComponent.getInitialProps(ctx)
    } else {
      componentProps =
        WrappedComponent.getInitialProps && (await WrappedComponent.getInitialProps(ctx))
    }

    // Pass accessToken to withAuthSync pages for ease of use
    return { ...componentProps, accessToken }
  }

  return Wrapper
}
